import {CustomEventService} from 'core-tracking-objects';

class AveStreamReadyTracking {

	static _getComponent() {
		return document.querySelector('.nm-md-ave-container.nm-active') || document.querySelector('#avecanvas');
	}

	static _createPayload(componentName) {
		return {
			detail: {
				eventInfo: {
					eventAction: 'non_interaction',
					eventName: 'acc ave stream ready'
				},
				attributes: {
					componentName: componentName,
					label: '',
					currentURL: window.location.href,
					targetURL: '',
					clickID: '',
					elementName: '',
					value: '',
					pos: ''
				}
			}
		};
	}

	static _getComponentName(aveContainer) {
		const isInlineRendering = aveContainer.closest('[data-module="inline-rendering"]') !== null;
		return isInlineRendering ? 'inline-rendering' : 'renderingLayer';
	}

	constructor() {
		if (AveStreamReadyTracking._instance) {
			return AveStreamReadyTracking._instance;
		}
		AveStreamReadyTracking._instance = this;

		document.addEventListener('ave-stream-ready-tracking', this._dispatchCustomEvent);
	}

	_dispatchCustomEvent() {
		const aveContainer = AveStreamReadyTracking._getComponent();

		if (!!aveContainer) {
			const componentName = AveStreamReadyTracking._getComponentName(aveContainer);
			const payload = AveStreamReadyTracking._createPayload(componentName);
			const customEvent = new CustomEvent(CustomEventService.CUSTOM_TRACKING_EVENT, payload);
			document.dispatchEvent(customEvent);
		}
	}

}

const aveStreamReadyTracking = new AveStreamReadyTracking();

export {aveStreamReadyTracking, AveStreamReadyTracking};
