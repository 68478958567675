import {cartService} from 'core-tracking-objects';
import {dpuApi} from 'configurator';
import {isModelStartPage} from './utils';
import {polling} from 'core-utils';
import {getProductItem} from './utils';
class CartObjectConfigurator {
	static get pollTime() {
		return 2000;
	}

	/**
	 * initialize
	 * register callback function
	 * @returns {void}
	 */
	initialize() {
		this.callBackFunction = this.getTrackingData.bind(this);
		cartService.register(this.callBackFunction);
		this.addEvents();
	}
	addEvents() {
		document.addEventListener('DPU_DATA_RECIEVED', () => this.performCartTracking(), {once:true});
	}
	performCartTracking() {
		const payload = this._getCartObject();
		const cartObject = new CustomEvent('configuration-update-tracking', payload);
		document.dispatchEvent(cartObject);
	}

	/**
	 * extracts the currency from a string. The currency is expected to be either at the beginning or end located and one or multiple characters which are not decimals
	 * Additionally it is expected that number and currency are separated via a whitespace symbol
	 * @param {string} priceWithCurrency input parameter with combined price and currency information
	 * @returns {string} - currency extracted from the input string
	 */
	extractCurrency(priceWithCurrency) {
		if (!priceWithCurrency) {
			return '';
		}

		const regexListToCheck = [/^([\D+.,]+?)[\d\s]+/gi, /[\d\s]+([\D+.,]+)$/gi];
		const detectedCurrency = regexListToCheck.reduce((accumulator, regex) => {
			if (typeof accumulator === 'undefined') {
				const resultRegex = regex.exec(priceWithCurrency);
				if (resultRegex && resultRegex.length > 1) {
					return resultRegex[1];
				}
			}

			return accumulator;
		}, undefined);

		return detectedCurrency ?? '';
	}

	/**
	 * getTrackingData - collects the relevant tracking information
	 * @returns {Promise} promise - returns promise for cartObjectConfigurator
	 */
	async getTrackingData() {
		try {
			if (!isModelStartPage()) {
				await polling.wait(
					dpuApi.isInitialized,
					CartObjectConfigurator.pollTime
				);
			}

			return this._getCartObject();
		}
		catch (e) {
			return {};
		}
	}

	/**
	 * get the cart data
	 * @returns {Object} the cart data
	 */
	_getCartObject() {
		const {
			nettoBaseRaw,
			nettoOptionsRaw,
			modelRaw,
			optionsRaw,
			rotrRaw,
			totalRaw,
			total
		} = dpuApi.getPrices();

		const basePrice = this._getBasePrice(nettoBaseRaw, nettoOptionsRaw, modelRaw, optionsRaw);
		const priceWithTax = this._getTotalPrice(modelRaw, optionsRaw);
		const cartTotal = this._getCartTotal(totalRaw, rotrRaw);
		const currency = this.extractCurrency(total);
		const priceMode = dpuApi.getPriceRateMode();

		return {
			'cartID': dpuApi.getConfigurationId(),
			'price': {
				'basePrice': basePrice, // overall configuration without taxes
				'currency': currency,
				'priceWithTax': priceWithTax, // basePrice + tax
				'cartTotal': cartTotal, // total price including shipping etc.
				'carlineBasePrice': this._getCarlineBasePrice(), // price of current model with smallest engine and without any addons
				'engineBasePrice': modelRaw ? modelRaw : '', // price of current model with current engine and without any addons
				'mode': priceMode || 'price' // is the mode a monthly installment = "rate" or full price = "price"
			},
			item: this._getCartItems()
		};
	}

	/**
	 * get the base cart proce
	 * @param {string} nettoBaseRaw - netto base price
	 * @param {string} nettoOptionsRaw - netto options price
	 * @param {string} modelRaw - model price
	 * @param {string} optionsRaw - options price
	 * @returns {string} - the base price
	 */
	_getBasePrice(nettoBaseRaw, nettoOptionsRaw, modelRaw, optionsRaw) {
		const hasNettoPrice = nettoBaseRaw && nettoBaseRaw !== 0;

		return (hasNettoPrice)
			? this._getTotalPrice(nettoBaseRaw, nettoOptionsRaw)
			: this._getTotalPrice(modelRaw, optionsRaw);
	}

	/**
	 * get price with tax
	 * @param {string} modelRaw - the raw model price
	 * @param {string} optionsRaw - the raw options price
	 * @returns {string} price with tax
	 */
	_getTotalPrice(modelRaw, optionsRaw) {
		return (modelRaw && optionsRaw) ? parseInt(modelRaw, 10) + parseInt(optionsRaw, 10) : '';
	}

	/**
	 * get total price of cart
	 * @param {string} totalRaw - the raw total price
	 * @param {string} rotrRaw - the raw rotr price
	 * @returns {string} total price of cart
	 */
	_getCartTotal(totalRaw, rotrRaw) {
		let cartTotal = totalRaw ? totalRaw : '';

		if (rotrRaw && rotrRaw !== 0) {
			cartTotal = rotrRaw;
		}

		return cartTotal;
	}

	/**
	 * get all cart item objects
	 * @returns {Array} the cart objects
	 */
	_getCartItems() {
		const cartItems = dpuApi.getSelectedItems();
		const incentives_ = dpuApi.getConfiguration().incentives;
		const families = dpuApi.getFamilies();

		let cartItemObjects = [];

		for (const cartItem in cartItems) {
			// eslint-disable-next-line no-prototype-builtins
			if (cartItems.hasOwnProperty(cartItem)) {
				cartItemObjects.push(getProductItem(cartItems[cartItem], incentives_, families));
			}
		}
		return cartItemObjects;
	}

	/**
	 * read price of current model with smallest engine an without any addons from SETUPS
	 * @returns {int} price of smallest engine or 0 if not in SETUPS
	 */
	_getCarlineBasePrice() {
		return SETUPS.get('nemo.CarlineBasePriceRaw') ? parseInt(SETUPS.get('nemo.CarlineBasePriceRaw'), 10) : 0;
	}
}

const cartObjectConfigurator = new CartObjectConfigurator();
export {cartObjectConfigurator, CartObjectConfigurator};
