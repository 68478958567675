import {productService} from 'core-tracking-objects';
import {dpuApi} from 'configurator';
import {isModelStartPage, isPageTypeNemo} from './utils';
import {polling} from 'core-utils';
import {getProductItem} from './utils';

class ProductObjectConfigurator {
	static get pollTime() {
		return '2000';
	}

	static get selectorsWithProductItems() {
		return {
			sClassSelectable: '.nm-j-configurator-status_11000',
			sClassSelectableConflict: '.nm-j-configurator-status_11100',
			sClassSelected: '.nm-j-configurator-status_11010',
			sClassSelectedContainedStandard: '.nm-j-configurator-status_10011'
		};
	}

	/**
	 * initialize class
	 * @returns {void} returns nothing
	 */
	initialize() {
		this.callBackFunction = this.getTrackingData.bind(this);

		productService.register(this.callBackFunction);
	}

	/**
	 * getTrackingData - collects the relevant tracking information
	 * @returns {Promise} promise - returns promise for pageObjectConfigurator
	 */
	async getTrackingData() {
		if (!isPageTypeNemo() && !this._isConfiguratorPage()) {
			return [];
		}

		try {
			if (!isModelStartPage()) {
				await polling.wait(
					dpuApi.isInitialized,
					ProductObjectConfigurator.pollTime
				);
			}

			return this._getProductItemsArray();
		}
		catch (e) {
			return [];
		}
	}

	/**
	 * retrieve selectable items from page and create array with tracking items
	 * @return {Array} array containing tracking items for page
	 */
	_getProductItemsArray() {
		const items = this._collectProductItems();
		return this._collectProductData(items);
	}

	/**
	 * retrieve all relevant items in DOM
	 * @returns {Array} selectable, selectable with conflict and selected
	 * configuration items in currently viewed page
	 */
	_collectProductItems() {
		return [
			...document.querySelectorAll(
				Object.values(
					ProductObjectConfigurator.selectorsWithProductItems
				).join(', ')
			)
		];
	}

	/**
	 * collected product data from DPU-API
	 * @param {Array} items - the DOM items to collect data for
	 * @returns {Array} product items - array of product objects in the
	 * expected form
	 */
	_collectProductData(items) {
		return items.reduce((accumulator, item) => {
			const currentConfiguratorId =
				item.getAttribute('data-configurator-id');

			if (!currentConfiguratorId) {
				return accumulator;
			}

			const currentItem = dpuApi.getItem(currentConfiguratorId);

			if (!currentItem) {
				return accumulator;
			}

			return accumulator.concat(getProductItem(currentItem));
		}, []);
	}

	/**
	 * get price info for DPU item
	 * @param {Object} dpuItem - the dpu item
	 * @return {Object} the product info
	 */
	_getPrice(dpuItem) {
		const basePrice = dpuItem.price ? {price: dpuItem.price} : {};
		// These are required on the documentation but not delivered at the
		// moment by the DPU
		// const taxRate = dpuItem.taxRate ? {taxRate: dpuItem.taxRate}:{};
		// const priceWithTax = dpuItem.price && dpuItem.taxRate ?
		// {priceWithTax: dpuItem.price*(1+(dpuItem.price/100))}:{};

		return Object.assign(
			{},
			basePrice
			// taxRate,
			// priceWithTax
		);
	}

	/**
	 * get attributes info for DPU item
	 * @param {Object} dpuItem - the dpu item
	 * @return {Object} the product info
	 */
	_getAttributes() {
		// We ignore this section until the product documentation is completed
		// let definedAttributesOnDocumentation = {
		// 	bodyType: '',
		// 	fuelType: '',
		// 	gearType: '',
		// 	driveType: '',
		// 	engine: '',
		// 	transmission: '',
		// 	ecom: '',
		// 	modelLine: '',
		// 	model: '',
		// 	modelYear: '',
		// 	modelShortCode: '',
		// 	dealerID: '',
		// 	rentalLocation: '',
		// 	rentalPeriod: '',
		// 	rentalDuration: ''
		// };

		return {};
	}

	/**
	 * _isConfiguratorPage
	 * @returns {boolean} returns true if a configurator-item is found in DOM
	 */
	_isConfiguratorPage() {
		return !!document.querySelector('.nm-j-configurator-item');
	}
}

const productObjectConfigurator = new ProductObjectConfigurator();
export {productObjectConfigurator, ProductObjectConfigurator};
