import {pageService} from 'core-tracking-objects';
import {isPageTypeNemo} from './utils';

const templateTypes = ['home'];

const selector = {
	content: '.nm-content'
};

class PageObjectConfigurator {
	/**
	 * initialize class
	 * @returns {void} returns nothing
	 */
	initialize() {
		this.callBackFunction = this.getTrackingData.bind(this);

		pageService.register(this.callBackFunction);
	}

	/**
	 * getTrackingData - collects the relevant tracking information
	 * @returns {Promise} promise - returns promise for pageObjectConfigurator
	 */
	async getTrackingData() {
		const pageObject = {
			attributes: {
				applicationName: 'nemo'
			}
		};

		return (isPageTypeNemo() && this._isValidPageTemplate()) ? pageObject : {};
	}

	/**
	 * _isValidPageTemplate
	 * @returns {boolean} returns true if data-template does not exist or is not contained in templateTypes / false if templateTypes const contains data-template value
	 */
	_isValidPageTemplate() {
		let isValidPageTemplate = false;

		let content = document.querySelector(selector.content);

		if (content) {
			const dataTemplate = content.getAttribute('data-template');

			if (dataTemplate && templateTypes.indexOf(dataTemplate) > -1) {
				isValidPageTemplate = false;
			}
			else {
				isValidPageTemplate = true;
			}
		}

		return isValidPageTemplate;
	}
}

const pageObjectConfigurator = new PageObjectConfigurator();
export {pageObjectConfigurator, PageObjectConfigurator};
