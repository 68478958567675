import {dpuApi} from 'configurator';
import {cartObjectConfigurator} from "./cart-object-configurator";


function _getAddedAndRemovedItems(payload) {
	const oldItems = payload?.detail?.changeEventData?.configurationItems;
	const newItems = dpuApi.getConfiguration()?.items;
	// compare difference to get the changes
	let addedItems = [];
	let removedItems = [];
	if (newItems && oldItems) {
		addedItems = newItems.filter(x => !oldItems.includes(x)) || [];
		removedItems = oldItems.filter(x => !newItems.includes(x)) || [];
	}


	return {addedItems, removedItems};
}

function _transformConfigItemToTracking(item) {
	return {
		productInfo : {
			productID: item?.id,
			productName: item?.name,
			manufacturer: "Audi"
		},
		category : {
			primaryCategory: item?.itemType,
			subCategory01: "",
			productType: "car part"
		},
		price: {
			price: item?.priceValue,
			currency: cartObjectConfigurator.extractCurrency(item?.price)
		},
		attributes : {
			productMbvId: item?.mbvId
		}
	};
}

export function getItemsPartOfPackage(catalog, changedItemId) {
	if (!catalog) {
		return [];
	}
	const searchItem = catalog[changedItemId]?.id;
	if (searchItem) {
		return Object.keys(catalog).filter((mbvid) => mbvid.split('_in_')[1] === searchItem) || [];
	}
	return [];
}

function _addOrRemoveItemsFromRelatedProducts(addedItems, catalog, customPayload, removedItems) {
	// Track changed changed Items without duplicates that were already tracked in packages.
	addedItems.map((item) => {
		if (!getItemsPartOfPackage(catalog, item).length) {
			customPayload.detail.attributes.relatedProducts.addedItems.carParts.push(_transformConfigItemToTracking(catalog[item], catalog));
		}
	});
	removedItems.map((item) => {
		if (!getItemsPartOfPackage(catalog, item).length) {
			customPayload.detail.attributes.relatedProducts.removedItems.carParts.push(_transformConfigItemToTracking(catalog[item], catalog));
		}
	});

	return customPayload;
}

export function populatePackages(changedItems, catalog){
	const packages = [];
	if (!changedItems) {
		return [];
	}
	changedItems.map(changedItem => {
		const changedItemsInPkg = getItemsPartOfPackage(catalog, changedItem);
		const cartParts = changedItemsInPkg.map((item) => _transformConfigItemToTracking(catalog[item]));
		if (changedItemsInPkg.length) {
			const packageInformation = _transformConfigItemToTracking(catalog[changedItems]);
			packages.push({
				...packageInformation,
				carParts: cartParts
			});
		}
	});
	return packages;
}

function _addPackagesToRelatedProducts(addedItems, catalog, removedItems, customPayload) {
	// Track changed packages and items related to a package
	const addedPackages = populatePackages(addedItems, catalog);
	const removedPackages = populatePackages(removedItems, catalog);
	if (addedPackages.length) {
		customPayload.detail.attributes.relatedProducts.addedItems.package.push(addedPackages);
	}
	if (removedPackages.length) {
		customPayload.detail.attributes.relatedProducts.removedItems.package.push(removedPackages);
	}

	return customPayload;
}

function _containsChangedItemsOrPackages(customPayload) {
	const {addedItems, removedItems} = customPayload.detail.attributes.relatedProducts;
	return addedItems.carParts.length !== 0 || addedItems.package.length !== 0 ||
  removedItems.carParts.length !== 0 || removedItems.package.length !== 0;
}

export function createAndDispatchEvent(payload, eventType, trackingPayload) {
	const catalog = dpuApi.getItems() || [];
	let {addedItems, removedItems} = _getAddedAndRemovedItems(payload);

	// initialise the tracking data
	const customPayload = trackingPayload;
	customPayload.detail.attributes.relatedProducts = {
		addedItems: {
			carParts: [],
			'package': []
		},
		removedItems: {
			carParts: [],
			'package': []
		}
	};
	customPayload.detail.attributes.currentURL = window && window.location.href;

	_addPackagesToRelatedProducts(addedItems, catalog, removedItems, customPayload);
	_addOrRemoveItemsFromRelatedProducts(addedItems, catalog, customPayload, removedItems);

	if (_containsChangedItemsOrPackages(customPayload)) {
		const customEvent = new CustomEvent(eventType, customPayload);
		document.dispatchEvent(customEvent);
	}
}
