import {CustomEventService} from 'core-tracking-objects';
import {useConfigurationTrackingEvent} from './utils';
import {createAndDispatchEvent} from './tracking-utils';


class ConfigurationCarstoreTracking {
	static getPayload() {
		return {
			detail: {
				eventInfo: {
					eventAction: 'callback',
					eventName: 'configurator - configuration start'
				},
				attributes: {
					componentName: '',
					label: '',
					currentURL: '',
					targetURL: '',
					clickID: '',
					elementName: '',
					value: '',
					pos: '',
					relatedProducts: {
						addedItems: {
							carParts: [],
							'package': []
						},
						removedItems: {
							carParts: [],
							'package': []
						}
					}
				}
			}
		};
	}

	constructor() {
		if (ConfigurationCarstoreTracking._instance) {
			return ConfigurationCarstoreTracking._instance;
		}

		ConfigurationCarstoreTracking._instance = this;
		this._dispatchCustomEvent = this._dispatchCustomEvent.bind(this);
		document.addEventListener('configuration-start-tracking', this._dispatchCustomEvent);
	}

	_dispatchCustomEvent(payload) {
		const startType = payload.detail && payload.detail.type ? payload.detail.type : null;

		if (this._isValidStartType(startType)) {
			const eventType = useConfigurationTrackingEvent(startType) ? 'configuration-tracking' : CustomEventService.CUSTOM_TRACKING_EVENT;
			createAndDispatchEvent(payload, eventType, ConfigurationCarstoreTracking.getPayload());
		}
	}

	_isValidStartType(type) {
		const validStartTypes = [
			'carstore',
			'configuration-start'
		];
		return validStartTypes.indexOf(type) > -1 || useConfigurationTrackingEvent(type);
	}
}

/**
 * Singleton instance
 * @type {ConfigurationCarstoreTracking}
 * @private
 * @static
 */

const configurationCarstoreTracking = new ConfigurationCarstoreTracking();

export {configurationCarstoreTracking, ConfigurationCarstoreTracking};
