import {aveStreamReadyTracking} from './configurator/tracking/ave-stream-ready-tracking';
import {configurationCarstoreTracking} from './configurator/tracking/configuration-carstore-tracking';
import {configurationChangeTracking} from './configurator/tracking/configuration-change-tracking';
import {cartObjectConfigurator} from './configurator/tracking/cart-object-configurator';
import {pageObjectConfigurator} from './configurator/tracking/page-object-configurator';
import {productObjectConfigurator} from './configurator/tracking/product-object-configurator';

cartObjectConfigurator.initialize();
pageObjectConfigurator.initialize();
productObjectConfigurator.initialize();

export {
	aveStreamReadyTracking,
	cartObjectConfigurator,
	configurationCarstoreTracking,
	configurationChangeTracking,
	pageObjectConfigurator,
	productObjectConfigurator
};
