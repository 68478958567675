import {createAndDispatchEvent} from './tracking-utils';

export const initialTrackingPayload = {
	detail: {
		eventInfo: {
			eventAction: 'callback',
			eventName: 'configurator - configuration change'
		},
		attributes: {
			componentName: '',
			label: '',
			currentURL: '',
			targetURL: '',
			clickID: '',
			elementName: '',
			value: '',
			pos: '',
			relatedProducts: {
				addedItems: {
					carParts: [],
					'package': []
				},
				removedItems: {
					carParts: [],
					'package': []
				}
			}
		}
	}
};

class ConfigurationChangeTracking {
	constructor() {
		if (ConfigurationChangeTracking._instance) {
			return ConfigurationChangeTracking._instance;
		}

		ConfigurationChangeTracking._instance = this;
		this._dispatchCustomEvent = this._dispatchCustomEvent.bind(this);
		document.addEventListener('configuration-start-tracking', this._dispatchCustomEvent);
	}

	// eslint-disable-next-line max-statements
	_dispatchCustomEvent(payload) {
		const eventInitiatorType = payload.detail && payload.detail.type ? payload.detail.type : null;
		if (!this._isValidChangeType(eventInitiatorType)) {
			return;
		}

		createAndDispatchEvent(payload, 'configuration-tracking', initialTrackingPayload);
	}


	_isValidChangeType(type) {
		const validStartTypes = [
			'configuration-change'
		];
		return validStartTypes.indexOf(type) > -1;
	}
}

/**
 * Singleton instance
 * @type {ConfigurationChangeTracking}
 * @private
 * @static
 */

const configurationChangeTracking = new ConfigurationChangeTracking();

export {configurationChangeTracking, ConfigurationChangeTracking};
