(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("core-tracking-objects"), require("configurator"), require("core-utils"));
	else if(typeof define === 'function' && define.amd)
		define("configurator-tracking", ["core-tracking-objects", "configurator", "core-utils"], factory);
	else if(typeof exports === 'object')
		exports["configurator-tracking"] = factory(require("core-tracking-objects"), require("configurator"), require("core-utils"));
	else
		root["configurator-tracking"] = factory(root["core-tracking-objects"], root["configurator"], root["core-utils"]);
})(globalThis, (__WEBPACK_EXTERNAL_MODULE__254__, __WEBPACK_EXTERNAL_MODULE__853__, __WEBPACK_EXTERNAL_MODULE__139__) => {
return 